import React from "react";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import ProfileCreationForm from "./ProfileCreationForm";

export default function MissingProfileInterceptor(props) {

  const {children} = props;
  const authUser = useAuthUser();

  // RENDER

  // If user is authenticated but has not profile, then ask for the creation of a profile
  if (authUser.authenticated && !authUser.profile)
    return <ProfileCreationForm/>
  else
    return children ? children : null;
}
