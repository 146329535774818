import React from "react";
import StringUtils from "../../../utils/StringUtils";
import "./InlineEditInputText.scss";

/**
 * Convenience hook to manage state of inline editable components when in editing mode.
 * @param value Initial value (raw, not formatted with formatInput)
 * @param onSubmit Operation to perform with the data when submitting
 * @param validate Validation callback
 * @param onCancel Operation to perform with the data when cancelling the edition
 * @param setBlurListener "react-onblur" callback to register a blur handler on the component, so that data can be submitted when focus is lost
 * @param unsetBlurListener "react-onblur" callback to unregister the blur handler on the component, so that data is not submitted many times when focus is lost
 * @param focusRef Ref on the input element that should receive focus when going into edit mode
 * @param formatInput Callback to format the initial input before it is given to the input component
 * @param formatOutput Callback to format the data given by the input component, before the hook caller can consume the data
 * @returns {{cancel: ((function(): void)|*), submit: ((function(): void)|*), changeValue: (value: unknown) => void, currentValue: unknown, validationError: string}}
 */
export default function useInlineEditInputEvents(value,
                                                 onSubmit,
                                                 validate,
                                                 onCancel,
                                                 setBlurListener,
                                                 unsetBlurListener,
                                                 focusRef,
                                                 formatInput,
                                                 formatOutput) {

  const formattedInitialValue = formatInput ? formatInput(value) : value;
  const [currentValue, setCurrentValue] = React.useState(formattedInitialValue);
  const [validationError, setValidationError] = React.useState("");
  const hasError = !StringUtils.isNullOrEmpty(validationError);

  // Persist the modifications and go back to the view state
  const submit = React.useCallback(() => {
    if (!hasError) {
      onSubmit(formatOutput ? formatOutput(currentValue) : currentValue);
    }
  }, [hasError, onSubmit, currentValue, formatOutput]);

  // Cancel the modifications and go back to the view state
  const cancel = React.useCallback(() => {
    // Put back initial value in case component is re-displayed later
    setCurrentValue(formattedInitialValue);
    onCancel();
  }, [formattedInitialValue, onCancel]);

  // Called when the focus is not in any of the children anymore; either submit or cancel the value and go back to the view state
  const onBlur = React.useCallback(event => {
    if (hasError || event.key === 'Escape') {
      cancel();
    } else {
      submit();
    }
  }, [hasError, submit, cancel]);

  // Validate the input
  React.useEffect(() => {
    if (validate) {
      setValidationError(validate(currentValue));
    }
  }, [currentValue, validate]);

  // Register an onBlur event handler
  React.useEffect(() => {
    setBlurListener(onBlur);
    return () => {
      return unsetBlurListener();
    }
  }, [setBlurListener, unsetBlurListener, onBlur]);

  // Put focus on input element on first render
  React.useEffect(() => {
    if (focusRef && focusRef.current)
      focusRef.current.focus();
  }, [focusRef]);

  return {
    currentValue, // Value currently entered by the user
    submit, // Submit callback to call when clicking a submit button
    cancel, // Cancel callback to call when clicking a cancel button
    changeValue: setCurrentValue, // Callback to call when the user changes the value of the input
    validationError // Any validation error of the input
  }
}
