import {useMemo} from "react";
import ApplicationStatusEnum from "../ApplicationStatusEnum.js";
import DateUtils from "../../../utils/DateUtils.js";

export default function useApplicationEffectiveStatusDate(application) {

  const effectiveDate = useMemo(() => {
    if (!application || !application.hire)
      return null;

    let effectiveDateAsString = undefined;
    if (application.status === ApplicationStatusEnum.HIRED)
      effectiveDateAsString = application.hire.hiringDate;
    else if (application.status === ApplicationStatusEnum.HIRING_CANCELLED)
      effectiveDateAsString = application.hire.dateCancelled;

    if (effectiveDateAsString)
      return DateUtils.localDateToTimelessIsoString(new Date(effectiveDateAsString));
    else
      return null;
  }, [application]);

  return effectiveDate;
};
