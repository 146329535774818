import React from "react";
import DatePicker from "react-datepicker";
import FormControl from "react-bootstrap/FormControl";

const DATEPICKER_DATE_FORMAT = "yyyy-MM-dd";

export default function DatePickerWithoutTime(props) {

  // The date passed to the widget and returned by the widget is a Javascript Date object.
  const {onChange, selected, onDateSelected, ...otherProps} = props;


  const isDateSelectedRef = React.useRef(false);

  const onChangePicker = React.useCallback( date => {
    onChange(date)
  }, [onChange]);

  const onSelect = React.useCallback(date => {
    // We can't fire onDateSelected here, because we have to wait for the date to change at the upper level
    // (so that the submit callback submits the new value). By using a ref to store our intention to call onDateSelected,
    // it is stored right now and we can then call onDateSelected in an effect later. The effect is triggered when the date is
    // updated at the higher level (and therefore onDateSelected too).
    isDateSelectedRef.current = true;
    onChange(date);
  }, [onChange]);

  // When a date is selected using the calendar, the onDateSelected will eventually change to be in accordance with
  // the new value. Trigger it only if we know that we selected a date in the calendar (the date also changes
  // when the user enters a valid date manually but we don't want to close everything yet because the user may continue
  // typing.
  React.useEffect(() => {
    if (isDateSelectedRef.current)
      onDateSelected();
  }, [onDateSelected]);

  const CustomInput = React.forwardRef((props, ref) =>
    <FormControl
      ref={ref}
      type="text"
      {...props}
    />
  );

  // Output a date picker with all original props except for the onChange and selected
  // onChange is triggered every time the date is valid when the user types in, and when the user selects a date
  // onSelect is triggered when the user selects a date
  return (
    <DatePicker
      onChange={onChangePicker}
      onSelect={onSelect}
      selected={selected}
      customInput={<CustomInput/>}
      dateFormat={DATEPICKER_DATE_FORMAT}
      strictParsing={true}
      {...otherProps}
    />
  );
}
