import React, {useCallback, useMemo, useState} from "react";
import StringUtils from "../../../utils/StringUtils.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import useFeedbackModal from "../../common/modals/useFeedbackModal.js";
import ApplicationStatusEnum from "../ApplicationStatusEnum.js";
import useMutationApplicationBatchUpdateStatus from "./useMutationApplicationBatchUpdateStatus.js";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import useLanguages from "../../infra-no-ui/translation/useLanguages.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import DatePickerWithoutTime from "../../common/widgets/DatePickerWithoutTime.js";
import "./useApplicationUpdateStatus.scss"

export default function useApplicationUpdateStatus(job) {

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "useBatchUpdateStatus");

  const [effectiveDate, setEffectiveDate] = useState(Date.now());

  const {getCurrentLanguageCode} = useLanguages();

  const { mutate: batchUpdateStatusMutate, errors: batchUpdateStatusErrors } = useMutationApplicationBatchUpdateStatus();
  const batchUpdateStatusErrorMsg = useServerErrorFormatter(batchUpdateStatusErrors, t("candidates:candidates_details_update_error"));

  const confirmHiringMsg =
    <>
      <p>{t("candidates:candidates_confirm_hiring_msg_1")}</p>
      {job &&
        <>
          <p>{t("candidates:candidates_confirm_hiring_msg_2")}</p>
          <div className={"reward-details"}>
            <p>{t("candidates:candidates_confirm_hiring_msg_3", {amount: StringUtils.formatMoney(job.commission, getCurrentLanguageCode())})}</p>
            <p className={"reward-label"}>{t("candidates:candidates_confirm_hiring_msg_3", {amount: StringUtils.formatMoney(job.commission, getCurrentLanguageCode())})}</p>
            {HtmlUtils.htmlToComponents(job.organization.paymentTerms)}
          </div>
          <div className={"effective-date"}>
            <span className={"effective-date-label"}>{t("candidates:candidates_hired_effective_msg")}</span>
            <div className={"effective-date-input-container"}>
              <DatePickerWithoutTime
                selected={effectiveDate}
                onChange={selectedDate => setEffectiveDate(selectedDate)}
                autofocus
              />
            </div>
          </div>
        </>
      }
    </>

  const confirmCancellationMsg =
    <>
      <p>{t("candidates:candidates_confirm_hiring_cancellation_msg")}</p>
      <div className={"effective-date"}>
        <span className={"effective-date-label"}>{t("candidates:candidates_hiring_cancelled_effective_msg")}</span>
        <div className={"effective-date-input-container"}>
          <DatePickerWithoutTime
            selected={effectiveDate}
            onChange={selectedDate => setEffectiveDate(selectedDate)}
            autofocus
          />
        </div>
      </div>

    </>

  const { launch: launchBatchUpdateStatus, FeedbackModal: BatchUpdateStatusModal } = useFeedbackModal({errorMsg: batchUpdateStatusErrorMsg});
  const { launch: launchBatchUpdateStatusWithConfirm, FeedbackModal: BatchUpdateStatusModalWithConfirm } = useFeedbackModal({
    errorMsg: batchUpdateStatusErrorMsg, confirmMsg: confirmHiringMsg,
    className: "ApplicationUpdateStatusConfirmHiringModal"
  });
  const { launch: launchBatchUpdateStatusForCancellation, FeedbackModal: BatchUpdateStatusModalForCancellation } = useFeedbackModal({
    errorMsg: batchUpdateStatusErrorMsg, confirmMsg: confirmCancellationMsg,
    className: "ApplicationUpdateStatusConfirmHiringCancelledModal"
  });

  const changeStatus = useCallback((applicationIds, status, effectiveDate = undefined) => {
    const variables = {};
    variables["ids"] = applicationIds;
    variables["status"] = status;
    if (effectiveDate)
      variables["effectiveDate"] = effectiveDate;
    return batchUpdateStatusMutate({ variables })
  }, [batchUpdateStatusMutate]);

  const launchChangeStatus = useCallback((selectedIds, status, onSuccess = undefined) => {
    let launcher;
    if (status === ApplicationStatusEnum.HIRED)
      launcher = launchBatchUpdateStatusWithConfirm
    else if (status === ApplicationStatusEnum.HIRING_CANCELLED)
      launcher = launchBatchUpdateStatusForCancellation
    else
      launcher = launchBatchUpdateStatus;

    return launcher(() => changeStatus(selectedIds, status, effectiveDate), onSuccess)
  }, [launchBatchUpdateStatus, launchBatchUpdateStatusWithConfirm, launchBatchUpdateStatusForCancellation, changeStatus, effectiveDate]);

  return useMemo(() => ({
    ready: !tLoading,
    launchChangeStatus,
    BatchUpdateStatusModal: <>{BatchUpdateStatusModal}{BatchUpdateStatusModalWithConfirm}{BatchUpdateStatusModalForCancellation}</>
  }), [tLoading, launchChangeStatus, BatchUpdateStatusModal, BatchUpdateStatusModalWithConfirm, BatchUpdateStatusModalForCancellation]);
};
