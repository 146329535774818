import React from "react";
import useAuthUser from "../../authentication-no-ui/useAuthUser";
import useMutationProfileUpdateLanguage from "./useMutationProfileUpdateLanguage";

export default function useUpdateAuthUserLanguage(/*props*/) {

  const authUser = useAuthUser();
  const { mutate } = useMutationProfileUpdateLanguage();

  return React.useCallback(languageCode => {
    if (authUser.authenticated) {
      const variables = {};
      variables["id"] = authUser.profile._id;
      variables["defaultLanguage"] = languageCode.toUpperCase();
      return mutate({variables})
        .catch((/*error*/) => {
          // Do not propagate error
        });
    } else {
      return Promise.resolve();
    }
  }, [authUser, mutate]);
};
