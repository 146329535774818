import HtmlUtils from "../../../utils/HtmlUtils.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import StringUtils from "../../../utils/StringUtils.js";

export default class PromptGenerator {

  constructor(lang, promptParams, organization, profile) {
    this.lang = lang;
    this.plainLanguage = this.lang === "FR" ? "French" : "English";
    this.promptParams = promptParams;
    this.organization = organization;
    this.profile = profile;
    const company = this.organization.company;
    const companyDescription = HtmlUtils.removeHtml(this.lang === "FR" ? this.organization.companyIntroFr : this.organization.companyIntroEn);
    const myName = [this.profile.firstName, this.profile.lastName].join(" ");
    this.context = [
      `Answer in ${this.plainLanguage}.`,
      `Use the following pieces of context to answer the question.`,
      !StringUtils.isNullOrEmpty(company) ? `Company name: ${company}`: null,
      !StringUtils.isNullOrEmpty(companyDescription) ? `Description of the company: ${companyDescription}` : null,
      !StringUtils.isNullOrEmpty(myName) ? `My name: ${myName}` : null
    ].filter(s => s !== null).join("\n");
  }

  getParam(name) {
    const param = TypeUtils.ensureArray(this.promptParams).find(p => p.name === name);
    return param && !StringUtils.isNullOrEmpty(param.value) ? param.value : "";
  }

  jobPostingPrompt() {

    const job = this.getParam("job");

    return `
      Create a job posting for ${job}. 
      Start with a short description of the company
      Generate a short introduction to the job opening.
      Add a "Responsibilities section" and give ten responsibilities of the job.
      Add a "Requirements section" and give 10 requirements of the job including technical skills, soft skills, 
      diplomas and experience.
      Add a section for the perks offered by the company.
      Add a message at the end for diversity and inclusion in the company.
      ${this.context}
    `;
  }

  jobSkillsPrompt() {

    const job = this.getParam("job");

    return `
      What are the most in-demand skills for a ${job} position? Answer in ${this.plainLanguage}. 
    `;
  }

  referralPromotionalMaterial() {
    const reward = this.getParam("reward");

    return [
      `Write a message to my employees to promote the company's employee referral program.`,
      !StringUtils.isNullOrEmpty(reward) ? `The maximum reward an employee can get is ${reward}.` : null,
      ``,
      !StringUtils.isNullOrEmpty(this.organization.paymentTerms) ? `The payment terms of the employee referral bonus are as follow: ${this.organization.paymentTerms}.`: null,
      ``,
      this.context
    ].filter(s => s !== null).join("\n");
  }

  referralStrategies() {
    return [
      `What are the best strategies to promote the company's employee referral program?`,
      this.context
    ].filter(s => s !== null).join("\n");
  }

  candidateReceiptEmailPrompt() {

    const job = this.getParam("job");
    const candidateName = this.getParam("candidateName");

    return [
      `Write a candidate application acknowledgement of receipt.`,
      !StringUtils.isNullOrEmpty(job) ? `The job is: ${job}` : null,
      !StringUtils.isNullOrEmpty(candidateName) ? `The candidate's name is: ${candidateName}` : null,
      this.context
    ].filter(s => s !== null).join("\n");
  }

  candidateRejectEmailPrompt() {
    const job = this.getParam("job");
    const candidateName = this.getParam("candidateName");

    return [
      `Write an email to a rejected candidate.`,
      !StringUtils.isNullOrEmpty(job) ? `The job is: ${job}` : null,
      !StringUtils.isNullOrEmpty(candidateName) ? `The candidate's name is: ${candidateName}` : null,
      this.context
    ].filter(s => s !== null).join("\n");
  }

  candidateHireLetterPrompt() {
    const job = this.getParam("job");
    const candidateName = this.getParam("candidateName");

    return [
      `Write a letter to hire a candidate.`,
      !StringUtils.isNullOrEmpty(job) ? `The job is: ${job}` : null,
      !StringUtils.isNullOrEmpty(candidateName) ? `The candidate's name is: ${candidateName}` : null,
      this.context
    ].filter(s => s !== null).join("\n");
  }
}

