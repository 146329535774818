import React from "react";
import useTranslationCommon from "../useTranslationCommon";
import useShowLoader from "../loading-widgets/useShowLoader";
import DateUtils from "../../../utils/DateUtils";
import StringUtils from "../../../utils/StringUtils";

export default function DisplayDate(props) {
  const { date } = props;
  const { t, loading: tLoading } = useTranslationCommon();
  const dateCalculator = DateUtils.dateDiff(Date.parse(date));
  useShowLoader(tLoading);

  return (
    <>
      {dateCalculator === 0 ? (
        <span>{t("common:common_today")}</span>
      ) : (
        <span>
          {t("common:common_period_days", {
            count: StringUtils.nullToZero(dateCalculator),
          })}
        </span>
      )}
    </>
  );
}
