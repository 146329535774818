import React from "react";
import Button from "react-bootstrap/Button";
import ActionLoadingComponent from "./ActionLoadingComponent";
import "./ActionLink.scss";

/**
 * Display a Button as a link. Accepts all Button properties, plus a boolean "loading" state.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ActionLink(props) {
  const { loading, children, className, disabled, onClick, ...otherProps } =
    props;

  const newClassName = ["ActionLink", className].join(" ");

  // When the link is used to open a modal, the link keeps the focus after the modal is closed.
  // Remove the focus so that the focus box does not remain around the link.
  const onClickWithBlur = React.useCallback(
    (event) => {
      document.activeElement.blur();
      if (onClick) onClick(event);
    },
    [onClick]
  );

  return (
    // Using a Bootstrap Button takes care of colors and tab navigation
    <Button
      {...otherProps}
      className={newClassName}
      disabled={loading || disabled}
      variant={"link"}
      onClick={onClickWithBlur}
    >
      <ActionLoadingComponent loading={loading}>
        {children}
      </ActionLoadingComponent>
    </Button>
  );
}
