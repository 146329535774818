import React from "react";
import useShowLoader from "../loading-widgets/useShowLoader";
import useTranslationCommon from "../useTranslationCommon";

/*
 * Pass this error component to an error boundary class such as ErrorBoundary to display an error message.
 * Error message is translated, so beware that this component can be used only in a boundary under the translation provider.
 */
export default function TranslatedContentError(/*props*/) {
  const {t, loading: tLoading} = useTranslationCommon();

  useShowLoader(tLoading, "TranslatedContentError");

  const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

  return (
    <div className={"TranslatedContentError"}>
      <h1>{t("common:page_error_title")}</h1>
      <p>
        {t("common:page_error_message")}
      </p>
      <p>
        <a href={"mailto:" + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a>
      </p>
    </div>
  );
}
