import {InMemoryCache} from "@apollo/client";

/**
 * Create the caching strategy for Apollo client.
 */
export function apolloCacheManager() {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          applicationSearchApprovedStatusGroupAndCount: _overwriteExisting(),
          applicationSearchApprovedDateGroupAndCount: _overwriteExisting(),
        }
      },
    }
  })
}

const _overwriteExisting = () => {
  return {
    merge: false, // Do not merge existing and incoming, overwrite existing
  }
}
