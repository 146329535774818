import { gql } from "@apollo/client";
import useDeferredQuery from "../../common/data-fetching/useDeferredQuery.js";

const QUERY_SCOPE = "chatGpt";
const QUERY_NAME = "chatGptAsk";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}(
    $employerId: ID!, 
    $prompt: String!, 
    $historyMessages: [ChatGptMessageInput]
  ) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(
        employerId: $employerId, 
        prompt: $prompt, 
        historyMessages: $historyMessages
      ) 
    }
  }
`;

export default function useLazyQueryChatGptAsk() {

  return useDeferredQuery(QUERY, QUERY_SCOPE, QUERY_NAME);
}
