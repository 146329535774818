import React, { useState } from "react";
import "./ButtonGroup.scss";

const ButtonGroup = (props) => {
  const { buttons, selectedId, onClick } = props;
  const [clickedId, setClickedId] = useState(selectedId);

  const handleClick = (event, id) => {
    setClickedId(id);
    onClick(id);
  };

  React.useEffect(() => {
    setClickedId(selectedId);
  }, [selectedId]);

  return (
    <>
      {buttons.map((buttonLabel) => (
        <button
          key={buttonLabel.id}
          name={buttonLabel.id}
          onClick={(event) => handleClick(event, buttonLabel.id)}
          className={
            buttonLabel.id === clickedId
              ? "customButton activeButton"
              : "customButton"
          }
        >
          {buttonLabel.label}
        </button>
      ))}
    </>
  );
};

export default ButtonGroup;
