import useContentModal from "../../common/modals/useContentModal.js";
import StandardPromptForm from "./StandardPromptForm.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import StandardPromptWidget from "./StandardPromptWidget.js";
import React from "react";

export default function StandardPrompt({onSelect, template, params = []}) {
  const {
    ContentModal: PromptModal,
    show: showPromptModal,
    hide: hidePromptModal,
  } = useContentModal("StandardPromptModal");

  const onCancel = (() => hidePromptModal());

  const onSubmit = (params => {
    hidePromptModal();
    onSelect(params);
  });

  const form = <StandardPromptForm template={template} onAbort={onCancel} onSuccess={onSubmit} params={params}/>;

  const paramNames = TypeUtils.ensureArray(params).map(param => param.name);

  const onClick = (() => {
    if (TypeUtils.arrayIsEmpty(paramNames))
      onSelect(params);
    else
      showPromptModal(form);
  });

  return (
    <>
      {PromptModal}
      <StandardPromptWidget onSelect={onClick} template={template}/>
    </>
  );
}
