import chatgpt from "../components/chatgpt/actions.js";

// To simplify things, there are no dynamic rules to check "mine" and "myorg". They depend on specific data
// and this will be checked on the GraphQL side. Here, we only want to do a fast check whether a page is
// available to the user or whether the user must be redirected. Anyway, the user has access to all the code
// of the app. When a user can access a page but not with specific data, data won't be made available by GraphQL.
const authorizationRules = {
  "employer": {

    // Static rules
    static: [
      // action1, action2, etc.
    ],

    // Dynamic rules
    dynamic: {
      [chatgpt.CHATGPT_VIEW]: (isGptEnabled) => isGptEnabled
    }

    // This role inherits authorizations from these other roles
    // inherits: ['otherRole']
  }
};

export default authorizationRules;
