class DateUtils {
  /**
   * Converts a Date object without time (expressed as a date in UTC, eg. 2021-12-20T00:00:00.000Z) to a Date object
   * in local time, so that getDate(), getHours() etc. will return 20 and 00.
   * @param date
   * @returns {Date}
   */
  static timelessDateToLocalDate = (date) => {
    const localTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + localTimezoneOffset);
  };

  /**
   * Converts a Date object in local time to a date Object without time
   * (expressed as a date in UTC, eg. 2021-12-20T00:00:00.000Z)
   * @param date
   * @returns {Date}
   */
  static localDateToTimelessDate = (date) => {
    const localTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - localTimezoneOffset);
  };

  // Converts a timeless date object (expressed as 2021-12-20T00:00:00.000Z) to a string.
  // Format is "YYYY-MM-DD"
  static timelessDateToString = (date) => {
    return this.localDateToTimelessIsoString(this.timelessDateToLocalDate(date));
  };

  // Apply format "YYYY-MM-DD" to a date object. Date is expressed using local timezone (for computing the day).
  static localDateToTimelessIsoString = (date) => {
    // Get year, month and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Converts a timeless date object (expressed as 2021-12-20T00:00:00.000Z) to a string.
  // Format is "YYYY-M-D"
  static timelessDateToShortString = (date) => {
    const localDate = this.timelessDateToLocalDate(date);

    // Get year, month and day
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1);
    const day = String(localDate.getDate());
    return `${year}-${month}-${day}`;
  };

  static timelessDateToLongString = (date, language) => {
    const localDate = this.timelessDateToLocalDate(date);

    const months = {
      en: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      fr: [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ],
    };

    const year = localDate.getFullYear();
    const month = months[language][localDate.getMonth()];
    const day = localDate.getDate();

    if (language === "fr") {
      return `${day} ${month} ${year}`;
    } else return `${month} ${day}, ${year}`;
  };

  /**
   * Return the number of days between a date and actual date.
   * @param startDate Start date of type Date
   * @return {number} Number of days
   */
  static dateDiff(startDate) {
    return Math.round((Date.now() - startDate) / (1000 * 60 * 60 * 24));
  }

  // Build an array of all dates between two dates
  static getDaysArray = function (startDate, endDate) {
    const allDates = [];
    for (
      const d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      allDates.push(DateUtils.timelessDateToShortString(new Date(d)));
    }
    return allDates;
  };
}

export default DateUtils;
