import React from "react";

/**
 * Displays a default header on every page. The header is passed as a prop.
 * This components also provides a ref so that the header can be replaced later, when the full menu is ready.
 * See BasePage for more explanations.
 * We have to use React.forwardRef when the reference is created in a component but instantiated
 * in a child.
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const HeaderContainer = React.forwardRef((props, ref) => (
  <div className="HeaderContainer" ref={ref}>
    {/* Display default header until ref is filled (or if it is never filled) */}
    {props.header}
  </div>
));

export default HeaderContainer;
