import {useCallback, useMemo} from "react";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import useFeedbackModal from "../../common/modals/useFeedbackModal.js";
import useMutationApplicationDelete from "./useMutationApplicationDelete.js";

export default function useApplicationDelete(confirmMsg, successMsg) {

  const {mutate, loading, errors} = useMutationApplicationDelete();

  const errorMsg = useServerErrorFormatter(errors);

  const deleteApplication = useCallback((applicationId) => {
    return mutate({
      variables: {
        id: applicationId,
      },
    });
  }, [mutate]);

  const { launch, FeedbackModal } = useFeedbackModal({
      successMsg: successMsg,
      errorMsg: errorMsg,
      confirmMsg: confirmMsg
    }
  );

  const onClick = useCallback((applicationId) => {
    launch(() => deleteApplication(applicationId));
  }, [launch, deleteApplication]);

  return useMemo(() => ({
    FeedbackModal,
    loading,
    onClick,
  }), [FeedbackModal, loading, onClick]);
};
