import {useCallback, useEffect, useMemo, useState} from "react";
import StringUtils from "../../../utils/StringUtils.js";

export default function useChatConversation(welcomeMessage) {

  const initialMessages = useMemo(() => {
    if (StringUtils.isNullOrEmpty(welcomeMessage))
      return [];
    else
      return [{
        source: "inbound",
        content: welcomeMessage
      }]
  }, [welcomeMessage]);

  const [messages, setMessages] = useState(initialMessages);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setMessages(initialMessages);
  }, [initialMessages]);

  const addOutboundMessage = useCallback((content, extraInfo = undefined) =>
    setMessages(prevMessages => [...prevMessages, {
      source: "outbound",
      content,
      extraInfo
    }]),
    []);

  const addInboundMessage = useCallback(content =>
      setMessages(prevMessages => [...prevMessages, {
        source: "inbound",
        content
      }]),
    []);

  const addErrorMessage = useCallback(content =>
      setMessages(prevMessages => [...prevMessages, {
        source: "error",
        content
      }]),
    []);

  const resetConversation = useCallback(() => {
    setMessages(initialMessages);
  }, [initialMessages]);

  return useMemo(() => ({
    addOutboundMessage,
    addInboundMessage,
    addErrorMessage,
    resetConversation,
    setIsSubmitting,
    messages,
    isSubmitting
  }), [addOutboundMessage, addInboundMessage, addErrorMessage, resetConversation, setIsSubmitting, messages, isSubmitting]);
};
