import useAuthorization from "./useAuthorization";

/**
 * Component that conditionally renders contents if current user has the authorization to perform a given action.
 * Content to display is given by props.yes() function.
 * Content to display if not authorized is given by props.no() function, or null if not provided.
 * We use the render prop pattern (displaying a function instead of props.children) because we don't want the component calling UserIsAuthorized
 * to evaluate the children of UserIsAuthorized unless the user has access to it.
 * @param props
 * @return {null|*}
 * @constructor
 */
export default function UserIsAuthorized(props) {

  const {action, data, yes, no} = props;
  const authorized = useAuthorization(action, data);

  // RENDER
  if (authorized)
    return yes();
  else
    return no ? no() : null;
}
