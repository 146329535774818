const JobSortEnum = {
  RELEVANCE: "RELEVANCE",
  DATE_POSTED_ASC: "DATE_POSTED_ASC",
  DATE_POSTED_DESC: "DATE_POSTED_DESC",
  CREATED_AT_ASC: "CREATED_AT_ASC",
  CREATED_AT_DESC: "CREATED_AT_DESC",
  COMMISSION_ASC: "COMMISSION_ASC",
  COMMISSION_DESC: "COMMISSION_DESC",
  TITLE_ASC: "TITLE_ASC",
  TITLE_DESC: "TITLE_DESC"
};

export default JobSortEnum;
