class MathUtils {
  /**
   * Returns a random integer between 0 (included) and max (excluded).
   * @param max Max integer (excluded)
   * @returns {number} Random int
   */
  static getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
}

export default MathUtils;
