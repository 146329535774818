import { gql } from "@apollo/client";
import useDeferredQuery from "../../common/data-fetching/useDeferredQuery.js";
import useLanguages from "../../infra-no-ui/translation/useLanguages.js";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobGenerateDescription";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: ID!, $lang: GlobalLanguageEnum!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(id: $id, lang: $lang)
    }
  }
`;

export default function useLazyQueryJobGenerateDescription() {

  const {getCurrentLanguageCode} = useLanguages();
  
  return useDeferredQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables: {
      lang: getCurrentLanguageCode().toUpperCase()
    }
  });
}
