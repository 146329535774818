import React from "react";
import "./PageSubtitle.scss";

export default function PageSubtitle(props) {
  const { colTitle } = props;
  return (
    <div className={"PageSubtitle"}>
      <h2>{colTitle}</h2>
    </div>
  );
}
