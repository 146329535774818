import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import useLanguages from "../infra-no-ui/translation/useLanguages";
import React from "react";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import useUpdateAuthUserLanguage from "../profile/mutate/useUpdateAuthUserLanguage";

function FooterItem(props) {
  const { to, children: childrenProp, ...otherProps } = props;

  // Wrap children with LinkContainer if a "to" property is provided
  // A div is required because LinkContainer cannot accept a string as a child
  const children = to ? (
    <LinkContainer to={to} exact>
      <div className="footer-item-link">{childrenProp}</div>
    </LinkContainer>
  ) : (
    childrenProp
  );

  return (
    <div className="FooterItem" {...otherProps}>
      {children}
    </div>
  );
}

function LanguageFooterItem() {
  const updateAuthUserLanguage = useUpdateAuthUserLanguage();

  const onClickLanguage = (event, languageCode) => {
    event.preventDefault();

    // Mark the whole page as loading
    setLoading(true);

    // Tell i18next to fetch the files for the new language
    // Callback is called when other language has been fetched, then it resolves the promise (probably because the whole
    // promise is intended to be thrown and caught by the new Suspense component, but we don't use it yet because
    // using Suspense for loading state is experimental).
    i18n
      .changeLanguage(languageCode, (/*err*/) => {
        // Errors must be caught in the callback, they are not propagated to the promise catch clause
        // But do nothing if an error occurs (such as a missing translation namespace file), let i18next use fallbacks
      })
      .then(() => {
        updateAuthUserLanguage(languageCode)
      })
      .finally(() => {
        // Whether an error occurred or not, stop the suspense that prevents the page from being rendered
        setLoading(false);
      });
  };

  /**
   * Returns the underlying data structure for the language footer. It returns an object with the following properties:
   *  "title": the language object that serves as the title of the language footer
   *  "items": an array of language objects that are the footer subitems
   *
   *  A language object has the following properties:
   *  "name": the 2-letter name of the language, viewable by the user, not to be translated (eg. FR)
   *  "code": the code of the language, used by i18n to retrieve translations (eg. fr)
   *
   *  If there are no other languages than the current language, returns null.
   *  If there is only one other language, returns it as the footer title.
   *  If there are many other languages, returns the current language as the title and all other available languages
   *  as subitems.
   *
   *  @return Underlying data structure as describe above
   */
  const getLanguageFooterStructure = () => {
    // Find current language in the list of defined languages (LANGUAGES).
    // For each language of the resolving chain used by i18n, check if it is in the list of LANGUAGES. Stop as soon as one is
    // found, it means it is the more specific. LANGUAGES[0] will always be somewhere in the resolving chain
    // because it is the fallback language, but it might not be the more specific.
    const currentLanguageCode = getCurrentLanguageCode();
    const currentLanguageName = getLanguageName(currentLanguageCode);

    // Alternate languages to select from
    const otherLanguages = getOtherLanguages();

    // If there is no other language, do not display footer item
    if (otherLanguages.length === 0) return null;

    // If there is only one alternative, display it as the footer item title (clickable), without footer subitems
    if (otherLanguages.length === 1)
      return {
        title: otherLanguages[0],
        items: [],
      };

    // If there are many alternatives, display the current language as the footer item title, then alternatives as subitems
    return {
      title: {
        code: currentLanguageCode,
        name: currentLanguageName,
      },
      items: otherLanguages,
    };
  };

  // We don't call useTranslationFooter because it is not required here to wait for the translations to be loaded.
  // If t() is needed, call useTranslationFooter and watch for the loading property.
  const { i18n } = useTranslation();
  const { getCurrentLanguageCode, getLanguageName, getOtherLanguages } =
    useLanguages();
  const [loading, setLoading] = React.useState(false);
  useShowLoader(loading, "FooterComponents");

  const languageFooterStructure = getLanguageFooterStructure();

  if (!languageFooterStructure) return null;

  // No subitems to display in language footer, only a click to switch to the other language
  if (languageFooterStructure.items.length === 0)
    return (
      <FooterItem
        onClick={(event) =>
          onClickLanguage(event, languageFooterStructure.title.code)
        }
      >
        {languageFooterStructure.title.name}
      </FooterItem>
    );

  // Subitems to display in language footer, display current language as dropdown title and then other languages
  const languageFooterItems = languageFooterStructure.items.map((language) => (
    <FooterItem
      key={language.code}
      onClick={(event) => onClickLanguage(event, language.code)}
    >
      {language.name}
    </FooterItem>
  ));

  return (
    <FooterItem
      title={languageFooterStructure.title.name}
      items={languageFooterItems}
    />
  );
}

export { FooterItem, LanguageFooterItem };
