import React from "react";
import "./ResetPasswordPage.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ResetPasswordForm from "./ResetPasswordForm";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import Page from "../common/page/Page";

export default function ResetPasswordPage() {
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  useShowLoader(tLoading, "ResetPasswordPage");

  // RENDER

  return (
    <Page className="ResetPasswordPage">
      <Page.Title text={t("authentication-ui:reset_password_title")} loading={tLoading}/>
      <Page.Content>
        <Row>
          <Col className={"reset-password-block-col"}>
            <ResetPasswordBlock />
          </Col>
        </Row>
      </Page.Content>
    </Page>
  );
}

function ResetPasswordBlock() {
  const { t } = useTranslationAuthenticationUi(useShowLoader);

  // RENDER

  return (
    <div className={"ResetPasswordBlock widget-block"}>
      <Row>
        <Col>
          <h1>{t("authentication-ui:reset_password_title")}</h1>
          <p>{t("authentication-ui:reset_password_msg")}</p>
          <ResetPasswordForm />
        </Col>
      </Row>
    </div>
  );
}
