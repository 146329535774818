import {useLocation, useHistory} from "react-router-dom";
import {useCallback, useMemo} from "react";

/**
 * Utilities to keep the referrer when navigating from one page to another using React Router.
 * Some React Router components such as Redirect keep the referrer in the location.state.referrer.
 * Other components and hooks (such as history.push) do not. Use "redirectTo" instead of history.push
 * so that the referrer is propagated.
 * When navigating back to the referrer page, typically after Sign In or Sign Up, use the "fallback"
 * (eg. HOME) when there is no referrer information.
 */
export default function useReferrer(fallback) {

  const location = useLocation();
  const history = useHistory();

  // Do not use the whole location object as dependency, many parts of it (namely key and state) change and that provokes an infinite render loop
  const locationStateReferrerPathname = location.state && location.state.referrer ? location.state.referrer.pathname : null;
  const locationStateReferrerSearch = location.state && location.state.referrer ? location.state.referrer.search : null;
  const fallbackReferrer = useMemo(() => ({pathname: fallback}), [fallback]);
  const locationStateReferrer = useMemo(() => ({
    pathname: locationStateReferrerPathname,
    search: locationStateReferrerSearch
  }), [locationStateReferrerPathname, locationStateReferrerSearch]);

  const locationPathname = location.pathname;
  const locationSearch = location.search;

  // Transform a target location (string or React Router location object) into a location with a state
  // containing the previous page as the referrer (ie. ignore the current page)
  const withCurrentAsReferrer = useCallback(target =>
      makeTargetWithReferrer(target, locationPathname, locationSearch),
    [locationPathname, locationSearch]);

  // Transform a target location (string or React Router location object) into a location with a state
  // containing the previous page as the referrer (ie. ignore the current page)
  const withPreviousAsReferrer = useCallback(target =>
      makeTargetWithReferrer(target, locationStateReferrer.pathname, locationStateReferrer.search)
    , [locationStateReferrer]);

  const referrer = useMemo(() => {
    const locationAsString = locationPathname + locationSearch;
    const referrerAsString = locationStateReferrerPathname + locationStateReferrerSearch;
    // Referrer is taken from the route, unless it is empty or it refers to the current page
    return (locationStateReferrerPathname && locationAsString !== referrerAsString) ?
      locationStateReferrer : fallbackReferrer
      ;
  }, [locationStateReferrer, locationStateReferrerPathname, locationStateReferrerSearch, locationPathname, locationSearch, fallbackReferrer])

  const redirectToReferrer = useCallback(() => {
    history.push(referrer);
  }, [history, referrer]);

  const redirectTo = useCallback((target, updateReferrer = true) => {
    history.push(updateReferrer ? withCurrentAsReferrer(target) : withPreviousAsReferrer(target))
  }, [history, withCurrentAsReferrer, withPreviousAsReferrer]);

  return useMemo(() => ({
    referrer,
    redirectToReferrer,
    redirectTo,
    withCurrentAsReferrer,
    withPreviousAsReferrer
  }), [referrer, redirectToReferrer, redirectTo, withCurrentAsReferrer, withPreviousAsReferrer]);
}

// Transform a target location (string or React Router location object) into a location with a state
// containing the previous page as the referrer (ie. ignore the current page)
const makeTargetWithReferrer = (target, referrerPathname, referrerSearch) => {
  const newTarget = {};
  if (typeof target === "object")
    Object.assign(newTarget, target)
  else
    newTarget.pathname = target;

  Object.assign(newTarget, {
    state: {
      referrer: {
        pathname: referrerPathname,
        search: referrerSearch
      }
    }
  });

  return newTarget;
};
